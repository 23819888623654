import isTouchDevice from './is-touch-device';
export default function waitWebViewInit() {
    // if it's not a touch device, there is no need to try to find Cordova API (speed improvement on desktop)
    if (!isTouchDevice()) {
        return Promise.resolve({});
    }
    return new Promise((resolve)=>{
        let checkingAttempts = 0;
        const maxCheckingAttempts = 100;
        const onWebViewEngineReady = ()=>{
            var _window_device;
            // Weird iOS bug: Device Info properties have `null` value
            if ((_window_device = window.device) === null || _window_device === void 0 ? void 0 : _window_device.uuid) {
                return resolve({
                    deviceInfo: window.device
                });
            }
            const onDeviceInfoReady = ()=>{
                document.removeEventListener('deviceready', onDeviceInfoReady, false);
                resolve({
                    deviceInfo: window.device
                });
            };
            document.addEventListener('deviceready', onDeviceInfoReady, false);
        };
        const checkWebViewEngine = ()=>{
            if (window.cordova) {
                return onWebViewEngineReady();
            }
            // if we haven't found even Cordova breadcrumbs, we shouldn't wait for 'window.cordova' object
            if (!window.Ionic && !window._cordovaNative) {
                return resolve({});
            }
            checkingAttempts++;
            // exit if we haven't found Cordova API yet after a max number of attempts
            if (checkingAttempts === maxCheckingAttempts) {
                return resolve({});
            }
            // continue checking every 300ms
            setTimeout(checkWebViewEngine, 300);
        };
        // iOS: create an async task in the event loop,
        // because Cordova breadcrumbs will appear only after a current task
        setTimeout(checkWebViewEngine, 0);
    });
}
